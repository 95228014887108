<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div id="app">
    <el-container style="min-height: 100vh; min-width: 1280px">
      <el-backtop></el-backtop>
      <el-header style="height: auto">
        <div
          id="web-title"
          style="
            height: 225px;
            /* width: 1250px; */
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            /* background: url(/pic/ClinSR-Title-BG.png); */
            background-size: contain;
            position: relative;
            display: flex;
          "
        >
          <div
            style="
              height: 225px;
              flex-grow: 1;
              background: url(/pic/ClinSR-Title-BG.png) 0% 0% / cover;
              transform: scaleX(-1);
            "
          ></div>
          <div
            class="content-width"
            style="width: 1250px; display: flex; justify-content: space-between"
          >
            <!-- <div style="/* display: inline-block; */ margin: auto 0">
              <a class="" href="/" style="display: flex"
                ><img
                  src="/pic/ClinSR-Title.png"
                  style="display: initial; width: 100%"
              /></a>
            </div> -->

            <div
              class="v-center"
              style="
                height: 225px;
                width: 1250px;
                display: flex;
                margin: 0 auto;
                justify-content: space-between;
                background: url(/pic/ClinSR-Title.png);
                background-size: contain;
                position: relative;
              "
            >
              <div style="position: absolute; left: 0; top: 0">
                <a class="" href="/"
                  ><div
                    style="background: #ff000000; height: 225px; width: 1250px"
                  ></div
                ></a>
              </div>
              <div style="position: absolute; right: 27px; top: 18px">
                <a href="https://idrblab.org/zhufeng.php" target="_bank"
                  ><img
                    src="/pic/idrb.png"
                    style="display: initial; width: 363px"
                /></a>
              </div>
            </div>
          </div>
          <div
            style="
              height: 225px;
              flex-grow: 1;
              background: url(/pic/ClinSR-Title-BG.png) 100% 0% / cover;
              transform: scaleX(-1);
            "
          ></div>
        </div>
        <div style="background: #526170; display: flex; height: 60px">
          <div
            class="content-width"
            style="display: flex; flex-direction: row-reverse"
          >
            <el-menu
              class="el-menu-demo"
              mode="horizontal"
              background-color="#526170"
              text-color="#fff"
              style="margin: auto 0"
            >
              <el-menu-item>
                <router-link to="/" class="no-line"
                  ><i class="fa fa-home" aria-hidden="true"></i>
                  Home</router-link
                >
              </el-menu-item>

              <el-submenu index="2">
                <template slot="title">
                  <i class="fa fa-area-chart" aria-hidden="true"></i>
                  Customized Assessment</template
                >
                <el-menu-item class="submenu-item" index="2-1">
                  <router-link
                    to="/disease-class-analysis"
                    class="no-line"
                    style="color: black"
                  >
                    By Disease Class</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item" index="2-2">
                  <router-link
                    to="/drug-modality-analysis"
                    class="no-line"
                    style="color: black"
                  >
                    By Drug Modality</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item" index="2-2">
                  <router-link
                    to="/user-defined-input"
                    class="no-line"
                    style="color: black"
                  >
                    By User-defined Input</router-link
                  >
                </el-menu-item>
              </el-submenu>
              <!-- <el-menu-item>
                <router-link to="/user-defined-input" class="no-line"
                  ><i class="fa fa-calendar" aria-hidden="true"></i> Drug
                  Timeline</router-link
                >
              </el-menu-item> -->
              <el-submenu index="4">
                <template slot="title">
                  <i class="fa fa-search-plus" aria-hidden="true"></i>
                  Search CTPs</template
                >
                <el-menu-item class="submenu-item" index="4-1">
                  <router-link
                    to="/search-drug-page"
                    class="no-line"
                    style="color: black"
                  >
                    By Drug</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item" index="4-2">
                  <router-link
                    to="/search-disease-page"
                    class="no-line"
                    style="color: black"
                  >
                    By Disease</router-link
                  >
                </el-menu-item>
              </el-submenu>
              <el-menu-item>
                <router-link to="/statistics" class="no-line"
                  ><i class="fa fa-pie-chart" aria-hidden="true"></i>Statistics
                </router-link>
              </el-menu-item>
              <el-menu-item>
                <router-link to="/contact" class="no-line"
                  ><i class="fa fa-address-book-o" aria-hidden="true"></i
                  >Contact</router-link
                >
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-header>

      <el-main>
        <router-view />
      </el-main>

      <br />
      <div style="display: none;">
        <iframe
          :src="iframe_url_clinsr"
          frameborder="0"
        ></iframe>
      </div>
      <el-footer id="page-foot" style="height: auto">
        <div id="page-foot-row" style="height: 258px">
          <el-row class="content-width" style="display: flex; height: 100%">
            <el-col :span="8">
              <h3 class="foot-title"><b>About ClinSR</b></h3>
              <div class="foot-content">
                <p>
                  ClinSR.org is a multifunctional platform that enables the
                  dynamic illustration of how ClinSR changes over time, the
                  automated update of ClinSRs for the coming decade, and the
                  customized evaluation of the ClinSR for any drug group of
                  interest.
                </p>
              </div>
            </el-col>

            <el-col :span="8">
              <h3 class="foot-title"><b>Visitor Map</b></h3>
              <div
                class="foot-content"
                style="text-align: center; padding-top: 22px"
              >
                <iframe
                  :src="iframe_url"
                  frameborder="0"
                  style="transform: scale(1.29)"
                ></iframe>
              </div>
            </el-col>
            <el-col :span="8">
              <h3 class="foot-title"><b>Correspondence</b></h3>
              <div class="foot-content">
                <p>
                  <b>Prof. Feng Zhu</b>
                </p>
                <p>
                  College of Pharmaceutical Sciences, Zhejiang University,
                  Hangzhou, China
                </p>
                <p>
                  <a
                    href="https://idrblab.org/zhufeng.php"
                    target="_blank"
                    >All rights are reserved by IDRB&nbsp;<i
                      class="fa fa-external-link"
                      aria-hidden="true"
                    ></i
                  ></a>
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>


<script>
// import AboutView from "./views/AboutView.vue";
import pathUrl from "./api/path";
export default {
  name: "App",
  data() {
    return {
      isCollapse: false,
      iframe_url: `${pathUrl.localUrl}/files/visitermap`,
      iframe_url_clinsr: `${pathUrl.localUrl}/files/visiter_clinsr`,
      test: ["a", "b"],
    };
  },
  components: {
    // AboutView,
  },
};
</script>


<style lang="less">
@purple: #526170;

#app {
  font-family: Harding, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.content-width {
  width: 1300px;
  margin: 0px auto !important;
}
.el-menu-item:hover,
.el-submenu:hover {
  outline: 0;
  background-color: #bce9f04f !important;
  border-radius: 10px;
}

p {
  margin: 5px 0;
}

h3 {
  margin: 15px 0;
}

.el-menu--horizontal {
  .el-submenu__title {
    border-radius: 10px;
    font-size: 20px;
    padding-right: 0;
  }
  .el-submenu__title:hover {
    background-color: #bce9f04f !important;
  }
  .el-submenu__icon-arrow {
    margin-top: 6px !important;
  }
  .el-menu-item,
  .el-submenu {
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    padding: 0px;
  }
}
.el-menu--popup {
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%) !important;
}

.el-menu--popup {
  background: #fff !important;
  border-radius: 5px !important;
  padding: 10px 0 !important;
}
.el-header,
.el-main,
.el-footer {
  padding: 0 !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

body {
  display: block;
  margin: 0;
  line-height: 1.42857;
  font-size: 20px;
}

.foot-title {
  color: #ffffff;
  border-bottom: 3px solid #fff;
  font-size: 24px;
  text-align: left;
  padding-top: 10px;
}

.foot-content {
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  line-height: 1.6;
}

.el-row {
  margin: 0 3%;
  margin-bottom: 20px;
}

.el-col {
  padding: 0 20px;
}

.el-menu-item,
.el-submenu {
  i {
    color: #ffffff !important;
    margin-right: 10px;
    font-size: 24px !important;
    margin-bottom: 8px;
  }
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #409eff00 !important;
  color: #ffffff !important;
}
/* .el-menu-item i:hover{
  color: #ffd04b
} */
// .el-menu-item,
// .el-submenu {
//   width: 100%;
// }
.no-line {
  text-decoration: none !important;
  padding: 17px;
}
a {
  color: #ff9800;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #ff5447;
  text-decoration: underline;
}
nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
  }
  a.router-link-exact-active {
    color: #42b983;
  }
}
.el-main {
  overflow: hidden;
}
.el-button,
.fs20,
.el-tag,
.el-message-box__content,
.el-upload-list__item,
.el-notification__content,
.el-select-dropdown__item,
.el-input--suffix .el-input__inner,
.el-input {
  font-size: 20px !important;
}
.el-backtop {
  box-shadow: 0 0 6px 1px #03a9f4 !important;
}
// #page-foot:before {
//   margin-left: -700px;
//   content: "";
//   display: block;
//   position: absolute;
//   //bottom: 0;
//   margin-top: 58px;
//   right: 0;
//   height: 200px;
//   background: #c6cfd7;
//   border-top-left-radius: 10px;
// }
#web-title:before {
  margin-left: -700px;
  content: "";
  display: block;
  position: absolute;
  //bottom: 0;
  margin-top: 58px;
  right: 0;
  height: 200px;
  background: #d7cdc6;
  border-top-left-radius: 10px;
}
#page-foot-row:before {
  //border-top-left-radius: 10px;
  background: #526170;
  left: 0%;
  //left: 50%;
  //margin-left: -650px;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  height: 276px;
}
.el-popover {
  border: 1px solid #6e7dd3 !important;
  /* font-size: 20px; */
  box-shadow: 0 2px 12px 0 #cfcece;
}
.el-loading-mask {
  border-radius: 10px;
}

.submenu-item {
  background: #fff !important;
  color: #000 !important;
  border-radius: 0 !important;
  // font-size: 16px !important;
}
.submenu-item:hover {
  background: #ff584c30 !important;
}
</style>

